import logo from './logo.svg';
import './App.css';
import axios from 'axios';

function App() {
  const form = new FormData();
form.append('fbuserid', '1');
form.append('fbusername', 'naraanar');
form.append('fbuseremail', 'monnaagii@gmail.com');
form.append('fbuserprofile', 'http://zurag.png');

axios.post()


  const handleClick = ()=>{
    axios.put('https://bbe0skyjh8.execute-api.us-east-2.amazonaws.com/mystage').then((response)=>console.log(response)).catch((err)=>console.log(err));
  }
  const handleClickHTTP = ()=>{
    axios.put('https://140rw9iyof.execute-api.us-east-2.amazonaws.com/items', {
      "fbuserid": "1",
      "fbusername": "naraa",
      "fbuseremail": "monnaagii@gmail.com",
      "fbuserpicture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?asid=107021811348484&height=50&width=50&ext=1625731549&hash=AeT1bpWr_m0LmQouaA0"
    }, { headers: {  authorization: "token1"}}).then((response)=>console.log(response)).catch((err)=>console.log(err));
  }
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p onClick={()=>handleClick()}>hi rest api</p>
        <p onClick={()=>handleClickHTTP()}>hi http</p>
        <p onClick={()=>handleClick}>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
